import React from 'react';

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  [key: string]: any;
}

export const PrimaryButton: React.FC<ButtonProps> = ({
  children,
  icon,
  ...props
}) => {
  return (
    <button className={'btn btn-primary'} {...props}>
      {icon && (
        <>
          <i className={`${icon}`} />{' '}
        </>
      )}
      {children}
    </button>
  );
};

export default PrimaryButton;
