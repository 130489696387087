import React from 'react';
import { Container, Row } from 'reactstrap';
import { graphql } from 'gatsby';
import { Helmet, useTranslation, Link } from 'gatsby-plugin-react-i18next';
import { SecondaryButton } from '../../components/buttons';

const NotFound: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`${t('name')} - ${t('slogan')}`}</title>
      </Helmet>
      <section id={'not-found'}>
        <Container>
          <Row>
            <div className={'wrapper'}>
              <h1>{t('not-found.title')}</h1>
              <Link to={'/'}>
                <SecondaryButton>{t('not-found.back')}</SecondaryButton>
              </Link>
            </div>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default NotFound;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
